import {inject, Injectable} from '@angular/core'
import {filter, first, from, Observable, of, switchMap, tap} from 'rxjs'
import {GetObjectCommand, S3Client} from '@aws-sdk/client-s3'
import {map} from 'rxjs/operators'
import {LOCAL_STORAGE, SIGNED_URL} from '../application/window.provider'
import {environment} from '../../environments/environment'
import {UserService} from './user.service'
import {InvokeCommand, LambdaClient} from '@aws-sdk/client-lambda'
import {IAwsCredentials} from '@ellen/user-be'


const IMAGE_CACHE_KEY = 'ELLEN_IMAGES'
export const IMAGE_CACHE_VERSION = '2'


export interface IAwsService {
  getStatusFromLambda: () => Observable<boolean>
}

@Injectable(
  {providedIn: 'root'}
)
export class AwsService implements IAwsService {

  public bucketName = environment.image_bucket

  private userService = inject(UserService)

  private ils = inject(LOCAL_STORAGE)

  private getSignedUrl = inject(SIGNED_URL)

  public getS3(): Observable<S3Client> {
    return this.userService.awsCredentials$.pipe(
      filter(Boolean),
      first(),
      // Switch map to token check here
      map(credentials =>
        new S3Client(this.toCredentials(credentials)))
    )
  }

  public getLambda(): Observable<LambdaClient> {
    return this.userService.awsCredentials$.pipe(
      filter(Boolean),
      first(),
      // Switch map to token check here
      map(credentials =>
        new LambdaClient(this.toCredentials(credentials)))
    )
  }

  public getStatusFromLambda(): Observable<boolean> {
    return this.getLambda()
      .pipe(
        switchMap(lambda => {
          const params = {
            FunctionName: 'ellen-users-status',
            Payload: JSON.stringify({id: this.userService.me$().id})
          }
          return from(lambda.send(new InvokeCommand(params)))
        }),
        map(res => {
          const result = res.Payload?.transformToString('utf-8')
          return result === 'true'
        })
      )
  }

  public getImageUrl(parent: string, id: string): Observable<string> {
    const cached = this.getCache(parent, id)
    if (cached) {
      return of(cached)
    }
    return this.generateCachedUrl(parent, id)
  }

  private generateCachedUrl(parent: string, id: string): Observable<string> {
    const key = `${parent}/${id}`
    const params = {
      Bucket: this.bucketName,
      Key: key
    }

    return this.getS3().pipe(
      switchMap(s3 =>
        from(this.getSignedUrl(s3, new GetObjectCommand(params), {
            expiresIn: 60 * 60 * 24 * 7
          }
        ))),
      tap((url: string) => {
        this.setCache(parent, id, url)
      })
    )
  }

  private getCache(parent: string, id: string): string | undefined {
    const cacheData = this.getCacheOrNot()
    if (!cacheData[`${parent}`]) {
      return
    }
    if (cacheData[`${parent}`][id]) {
      if (cacheData[`${parent}`][id].expires > Date.now()) {
        return cacheData[`${parent}`][id].url
      }
    }
    return
  }

  private setCache(parent: string, id: string, url: string): void {
    const cacheData = this.getCacheOrNot()

    // We have to redo the links every 12 hours.
    if (cacheData[`${parent}`]) {
      cacheData[`${parent}`][id] = {
        url,
        expires: Date.now() + 3600 * 2 * 1000,
        created: Date.now()
      }
    } else {
      cacheData[`${parent}`] = {
        [id]: {
          url,
          expires: Date.now() + 3600 * 2 * 1000,
          created: Date.now()
        }
      }
    }
    cacheData.version = IMAGE_CACHE_VERSION
    this.ils.setItem(IMAGE_CACHE_KEY, JSON.stringify(cacheData))
  }

  private getCacheOrNot(): any {
    let cacheData: any
    try {
      cacheData = JSON.parse(this.ils.getItem(IMAGE_CACHE_KEY)!)
      if (cacheData.version !== IMAGE_CACHE_VERSION) {
        cacheData = null
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_e) {
      cacheData = {version: IMAGE_CACHE_VERSION}
    }
    return cacheData ?? {version: IMAGE_CACHE_VERSION}
  }

  private toCredentials(credentials: IAwsCredentials) {
    return {
      region: 'eu-west-1',
      credentials: {
        accessKeyId: credentials.accessKey,
        secretAccessKey: credentials.secretKey,
        sessionToken: credentials.sessionToken
      }
    }
  }
}