import {Component, inject, input, model, output} from '@angular/core'
import {MatIcon} from '@angular/material/icon'
import {Observable} from 'rxjs'
import {ConfigService} from '../../../services/config.service'
import {map, switchMap} from 'rxjs/operators'
import {AwsService} from '../../../services/aws.service'
import {PutObjectCommand, PutObjectCommandInput} from '@aws-sdk/client-s3'

@Component({
  selector: 'eln-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  imports: [
    MatIcon
  ]
})
export class AvatarComponent {
  public image = model.required<string | undefined>()

  public size = input.required<number>()
  public disabled = input<boolean>(false)
  public selected = input<boolean>(false)
  public defaultIcon = input<string>('person')
  public canEdit = input<boolean>(false)

  public avatarChange = output<{ id: string, url: string | undefined }>()

  private configService = inject(ConfigService)

  private aws = inject(AwsService)

  public onFileChanged(event: any) {
    if (event.target.files.length === 0) {
      return
    }
    const file: File = event.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      if (file) {
        // Save image as string
        const dataUrl = reader.result as string
        this.image.set(dataUrl)

        // Create upload file
        const mimeType = file.type

        this.uploadImageData(mimeType)
      }
    }
    reader.readAsDataURL(file)
  }

  private uploadImageData(contentType: string): void {
    this.uploadImage(contentType).subscribe({
      next: (id: string) => {
        this.avatarChange.emit({
          id,
          url: this.image()
        })
      }
    })
  }

  private uploadImage(contentType: string): Observable<string> {
    const tempId = crypto.randomUUID()

    return this.aws.getS3()
      .pipe(
        switchMap((s3) => {
          const base64String = this.image()!.split(',')[1]
          const binaryString = atob(base64String)
          const byteArray = new Uint8Array(binaryString.length)

          for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i)
          }
          const params: PutObjectCommandInput = {
            Bucket: this.aws.bucketName,
            Key: `${this.configService.loggedInUser$()!.sub}/${tempId}`,
            ContentType: contentType,
            CacheControl: `max=age=${60 * 60 * 24 * 365}`,
            Body: byteArray
          }
          return s3.send(new PutObjectCommand(params))
        }),
        map(() => tempId)
      )
  }
}
