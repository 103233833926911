import { loadRestJsonErrorCode, parseJsonBody as parseBody, parseJsonErrorBody as parseErrorBody } from "@aws-sdk/core";
import { requestBuilder as rb } from "@smithy/core";
import { _json, collectBody, decorateServiceException as __decorateServiceException, expectBoolean as __expectBoolean, expectInt32 as __expectInt32, expectLong as __expectLong, expectNonNull as __expectNonNull, expectNumber as __expectNumber, expectObject as __expectObject, expectString as __expectString, isSerializableHeaderValue, limitedParseDouble as __limitedParseDouble, map, parseEpochTimestamp as __parseEpochTimestamp, serializeFloat as __serializeFloat, take, withBaseException } from "@smithy/smithy-client";
import { LambdaServiceException as __BaseException } from "../models/LambdaServiceException";
import { CodeSigningConfigNotFoundException, CodeStorageExceededException, CodeVerificationFailedException, EC2AccessDeniedException, EC2ThrottledException, EC2UnexpectedException, EFSIOException, EFSMountConnectivityException, EFSMountFailureException, EFSMountTimeoutException, ENILimitReachedException, InvalidCodeSignatureException, InvalidParameterValueException, InvalidRequestContentException, InvalidRuntimeException, InvalidSecurityGroupIDException, InvalidSubnetIDException, InvalidZipFileException, KMSAccessDeniedException, KMSDisabledException, KMSInvalidStateException, KMSNotFoundException, PolicyLengthExceededException, PreconditionFailedException, ProvisionedConcurrencyConfigNotFoundException, RecursiveInvocationException, RequestTooLargeException, ResourceConflictException, ResourceInUseException, ResourceNotFoundException, ResourceNotReadyException, ServiceException, SnapStartException, SnapStartNotReadyException, SnapStartTimeoutException, SubnetIPAddressLimitReachedException, TooManyRequestsException, UnsupportedMediaTypeException } from "../models/models_0";
export const se_AddLayerVersionPermissionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy");
  b.p("LayerName", () => input.LayerName, "{LayerName}", false);
  b.p("VersionNumber", () => input.VersionNumber.toString(), "{VersionNumber}", false);
  const query = map({
    [_RI]: [, input[_RI]]
  });
  let body;
  body = JSON.stringify(take(input, {
    Action: [],
    OrganizationId: [],
    Principal: [],
    StatementId: []
  }));
  b.m("POST").h(headers).q(query).b(body);
  return b.build();
};
export const se_AddPermissionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2015-03-31/functions/{FunctionName}/policy");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  body = JSON.stringify(take(input, {
    Action: [],
    EventSourceToken: [],
    FunctionUrlAuthType: [],
    Principal: [],
    PrincipalOrgID: [],
    RevisionId: [],
    SourceAccount: [],
    SourceArn: [],
    StatementId: []
  }));
  b.m("POST").h(headers).q(query).b(body);
  return b.build();
};
export const se_CreateAliasCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2015-03-31/functions/{FunctionName}/aliases");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  body = JSON.stringify(take(input, {
    Description: [],
    FunctionVersion: [],
    Name: [],
    RoutingConfig: _ => se_AliasRoutingConfiguration(_, context)
  }));
  b.m("POST").h(headers).b(body);
  return b.build();
};
export const se_CreateCodeSigningConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2020-04-22/code-signing-configs");
  let body;
  body = JSON.stringify(take(input, {
    AllowedPublishers: _ => _json(_),
    CodeSigningPolicies: _ => _json(_),
    Description: [],
    Tags: _ => _json(_)
  }));
  b.m("POST").h(headers).b(body);
  return b.build();
};
export const se_CreateEventSourceMappingCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2015-03-31/event-source-mappings");
  let body;
  body = JSON.stringify(take(input, {
    AmazonManagedKafkaEventSourceConfig: _ => _json(_),
    BatchSize: [],
    BisectBatchOnFunctionError: [],
    DestinationConfig: _ => _json(_),
    DocumentDBEventSourceConfig: _ => _json(_),
    Enabled: [],
    EventSourceArn: [],
    FilterCriteria: _ => _json(_),
    FunctionName: [],
    FunctionResponseTypes: _ => _json(_),
    KMSKeyArn: [],
    MaximumBatchingWindowInSeconds: [],
    MaximumRecordAgeInSeconds: [],
    MaximumRetryAttempts: [],
    MetricsConfig: _ => _json(_),
    ParallelizationFactor: [],
    ProvisionedPollerConfig: _ => _json(_),
    Queues: _ => _json(_),
    ScalingConfig: _ => _json(_),
    SelfManagedEventSource: _ => _json(_),
    SelfManagedKafkaEventSourceConfig: _ => _json(_),
    SourceAccessConfigurations: _ => _json(_),
    StartingPosition: [],
    StartingPositionTimestamp: _ => _.getTime() / 1000,
    Tags: _ => _json(_),
    Topics: _ => _json(_),
    TumblingWindowInSeconds: []
  }));
  b.m("POST").h(headers).b(body);
  return b.build();
};
export const se_CreateFunctionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2015-03-31/functions");
  let body;
  body = JSON.stringify(take(input, {
    Architectures: _ => _json(_),
    Code: _ => se_FunctionCode(_, context),
    CodeSigningConfigArn: [],
    DeadLetterConfig: _ => _json(_),
    Description: [],
    Environment: _ => _json(_),
    EphemeralStorage: _ => _json(_),
    FileSystemConfigs: _ => _json(_),
    FunctionName: [],
    Handler: [],
    ImageConfig: _ => _json(_),
    KMSKeyArn: [],
    Layers: _ => _json(_),
    LoggingConfig: _ => _json(_),
    MemorySize: [],
    PackageType: [],
    Publish: [],
    Role: [],
    Runtime: [],
    SnapStart: _ => _json(_),
    Tags: _ => _json(_),
    Timeout: [],
    TracingConfig: _ => _json(_),
    VpcConfig: _ => _json(_)
  }));
  b.m("POST").h(headers).b(body);
  return b.build();
};
export const se_CreateFunctionUrlConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2021-10-31/functions/{FunctionName}/url");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  body = JSON.stringify(take(input, {
    AuthType: [],
    Cors: _ => _json(_),
    InvokeMode: []
  }));
  b.m("POST").h(headers).q(query).b(body);
  return b.build();
};
export const se_DeleteAliasCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/functions/{FunctionName}/aliases/{Name}");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  b.p("Name", () => input.Name, "{Name}", false);
  let body;
  b.m("DELETE").h(headers).b(body);
  return b.build();
};
export const se_DeleteCodeSigningConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2020-04-22/code-signing-configs/{CodeSigningConfigArn}");
  b.p("CodeSigningConfigArn", () => input.CodeSigningConfigArn, "{CodeSigningConfigArn}", false);
  let body;
  b.m("DELETE").h(headers).b(body);
  return b.build();
};
export const se_DeleteEventSourceMappingCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/event-source-mappings/{UUID}");
  b.p("UUID", () => input.UUID, "{UUID}", false);
  let body;
  b.m("DELETE").h(headers).b(body);
  return b.build();
};
export const se_DeleteFunctionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/functions/{FunctionName}");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  b.m("DELETE").h(headers).q(query).b(body);
  return b.build();
};
export const se_DeleteFunctionCodeSigningConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2020-06-30/functions/{FunctionName}/code-signing-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  b.m("DELETE").h(headers).b(body);
  return b.build();
};
export const se_DeleteFunctionConcurrencyCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2017-10-31/functions/{FunctionName}/concurrency");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  b.m("DELETE").h(headers).b(body);
  return b.build();
};
export const se_DeleteFunctionEventInvokeConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2019-09-25/functions/{FunctionName}/event-invoke-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  b.m("DELETE").h(headers).q(query).b(body);
  return b.build();
};
export const se_DeleteFunctionUrlConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2021-10-31/functions/{FunctionName}/url");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  b.m("DELETE").h(headers).q(query).b(body);
  return b.build();
};
export const se_DeleteLayerVersionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2018-10-31/layers/{LayerName}/versions/{VersionNumber}");
  b.p("LayerName", () => input.LayerName, "{LayerName}", false);
  b.p("VersionNumber", () => input.VersionNumber.toString(), "{VersionNumber}", false);
  let body;
  b.m("DELETE").h(headers).b(body);
  return b.build();
};
export const se_DeleteProvisionedConcurrencyConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2019-09-30/functions/{FunctionName}/provisioned-concurrency");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, __expectNonNull(input[_Q], `Qualifier`)]
  });
  let body;
  b.m("DELETE").h(headers).q(query).b(body);
  return b.build();
};
export const se_GetAccountSettingsCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2016-08-19/account-settings");
  let body;
  b.m("GET").h(headers).b(body);
  return b.build();
};
export const se_GetAliasCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/functions/{FunctionName}/aliases/{Name}");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  b.p("Name", () => input.Name, "{Name}", false);
  let body;
  b.m("GET").h(headers).b(body);
  return b.build();
};
export const se_GetCodeSigningConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2020-04-22/code-signing-configs/{CodeSigningConfigArn}");
  b.p("CodeSigningConfigArn", () => input.CodeSigningConfigArn, "{CodeSigningConfigArn}", false);
  let body;
  b.m("GET").h(headers).b(body);
  return b.build();
};
export const se_GetEventSourceMappingCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/event-source-mappings/{UUID}");
  b.p("UUID", () => input.UUID, "{UUID}", false);
  let body;
  b.m("GET").h(headers).b(body);
  return b.build();
};
export const se_GetFunctionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/functions/{FunctionName}");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_GetFunctionCodeSigningConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2020-06-30/functions/{FunctionName}/code-signing-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  b.m("GET").h(headers).b(body);
  return b.build();
};
export const se_GetFunctionConcurrencyCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2019-09-30/functions/{FunctionName}/concurrency");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  b.m("GET").h(headers).b(body);
  return b.build();
};
export const se_GetFunctionConfigurationCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/functions/{FunctionName}/configuration");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_GetFunctionEventInvokeConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2019-09-25/functions/{FunctionName}/event-invoke-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_GetFunctionRecursionConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2024-08-31/functions/{FunctionName}/recursion-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  b.m("GET").h(headers).b(body);
  return b.build();
};
export const se_GetFunctionUrlConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2021-10-31/functions/{FunctionName}/url");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_GetLayerVersionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2018-10-31/layers/{LayerName}/versions/{VersionNumber}");
  b.p("LayerName", () => input.LayerName, "{LayerName}", false);
  b.p("VersionNumber", () => input.VersionNumber.toString(), "{VersionNumber}", false);
  let body;
  b.m("GET").h(headers).b(body);
  return b.build();
};
export const se_GetLayerVersionByArnCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2018-10-31/layers");
  const query = map({
    [_f]: [, "LayerVersion"],
    [_A]: [, __expectNonNull(input[_A], `Arn`)]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_GetLayerVersionPolicyCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy");
  b.p("LayerName", () => input.LayerName, "{LayerName}", false);
  b.p("VersionNumber", () => input.VersionNumber.toString(), "{VersionNumber}", false);
  let body;
  b.m("GET").h(headers).b(body);
  return b.build();
};
export const se_GetPolicyCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/functions/{FunctionName}/policy");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_GetProvisionedConcurrencyConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2019-09-30/functions/{FunctionName}/provisioned-concurrency");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, __expectNonNull(input[_Q], `Qualifier`)]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_GetRuntimeManagementConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2021-07-20/functions/{FunctionName}/runtime-management-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_InvokeCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = map({}, isSerializableHeaderValue, {
    "content-type": "application/octet-stream",
    [_xait]: input[_IT],
    [_xalt]: input[_LT],
    [_xacc]: input[_CC]
  });
  b.bp("/2015-03-31/functions/{FunctionName}/invocations");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  if (input.Payload !== undefined) {
    body = input.Payload;
  }
  b.m("POST").h(headers).q(query).b(body);
  return b.build();
};
export const se_InvokeAsyncCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/octet-stream"
  };
  b.bp("/2014-11-13/functions/{FunctionName}/invoke-async");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  if (input.InvokeArgs !== undefined) {
    body = input.InvokeArgs;
  }
  b.m("POST").h(headers).b(body);
  return b.build();
};
export const se_InvokeWithResponseStreamCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = map({}, isSerializableHeaderValue, {
    "content-type": "application/octet-stream",
    [_xait]: input[_IT],
    [_xalt]: input[_LT],
    [_xacc]: input[_CC]
  });
  b.bp("/2021-11-15/functions/{FunctionName}/response-streaming-invocations");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  if (input.Payload !== undefined) {
    body = input.Payload;
  }
  b.m("POST").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListAliasesCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/functions/{FunctionName}/aliases");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_FV]: [, input[_FV]],
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListCodeSigningConfigsCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2020-04-22/code-signing-configs");
  const query = map({
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListEventSourceMappingsCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/event-source-mappings");
  const query = map({
    [_ESA]: [, input[_ESA]],
    [_FN]: [, input[_FN]],
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListFunctionEventInvokeConfigsCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2019-09-25/functions/{FunctionName}/event-invoke-config/list");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListFunctionsCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/functions");
  const query = map({
    [_MR]: [, input[_MR]],
    [_FV]: [, input[_FV]],
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListFunctionsByCodeSigningConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2020-04-22/code-signing-configs/{CodeSigningConfigArn}/functions");
  b.p("CodeSigningConfigArn", () => input.CodeSigningConfigArn, "{CodeSigningConfigArn}", false);
  const query = map({
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListFunctionUrlConfigsCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2021-10-31/functions/{FunctionName}/urls");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListLayersCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2018-10-31/layers");
  const query = map({
    [_CR]: [, input[_CR]],
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()],
    [_CA]: [, input[_CA]]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListLayerVersionsCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2018-10-31/layers/{LayerName}/versions");
  b.p("LayerName", () => input.LayerName, "{LayerName}", false);
  const query = map({
    [_CR]: [, input[_CR]],
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()],
    [_CA]: [, input[_CA]]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListProvisionedConcurrencyConfigsCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2019-09-30/functions/{FunctionName}/provisioned-concurrency");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_L]: [, "ALL"],
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_ListTagsCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2017-03-31/tags/{Resource}");
  b.p("Resource", () => input.Resource, "{Resource}", false);
  let body;
  b.m("GET").h(headers).b(body);
  return b.build();
};
export const se_ListVersionsByFunctionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/functions/{FunctionName}/versions");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_M]: [, input[_M]],
    [_MI]: [() => input.MaxItems !== void 0, () => input[_MI].toString()]
  });
  let body;
  b.m("GET").h(headers).q(query).b(body);
  return b.build();
};
export const se_PublishLayerVersionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2018-10-31/layers/{LayerName}/versions");
  b.p("LayerName", () => input.LayerName, "{LayerName}", false);
  let body;
  body = JSON.stringify(take(input, {
    CompatibleArchitectures: _ => _json(_),
    CompatibleRuntimes: _ => _json(_),
    Content: _ => se_LayerVersionContentInput(_, context),
    Description: [],
    LicenseInfo: []
  }));
  b.m("POST").h(headers).b(body);
  return b.build();
};
export const se_PublishVersionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2015-03-31/functions/{FunctionName}/versions");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  body = JSON.stringify(take(input, {
    CodeSha256: [],
    Description: [],
    RevisionId: []
  }));
  b.m("POST").h(headers).b(body);
  return b.build();
};
export const se_PutFunctionCodeSigningConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2020-06-30/functions/{FunctionName}/code-signing-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  body = JSON.stringify(take(input, {
    CodeSigningConfigArn: []
  }));
  b.m("PUT").h(headers).b(body);
  return b.build();
};
export const se_PutFunctionConcurrencyCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2017-10-31/functions/{FunctionName}/concurrency");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  body = JSON.stringify(take(input, {
    ReservedConcurrentExecutions: []
  }));
  b.m("PUT").h(headers).b(body);
  return b.build();
};
export const se_PutFunctionEventInvokeConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2019-09-25/functions/{FunctionName}/event-invoke-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  body = JSON.stringify(take(input, {
    DestinationConfig: _ => _json(_),
    MaximumEventAgeInSeconds: [],
    MaximumRetryAttempts: []
  }));
  b.m("PUT").h(headers).q(query).b(body);
  return b.build();
};
export const se_PutFunctionRecursionConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2024-08-31/functions/{FunctionName}/recursion-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  body = JSON.stringify(take(input, {
    RecursiveLoop: []
  }));
  b.m("PUT").h(headers).b(body);
  return b.build();
};
export const se_PutProvisionedConcurrencyConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2019-09-30/functions/{FunctionName}/provisioned-concurrency");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, __expectNonNull(input[_Q], `Qualifier`)]
  });
  let body;
  body = JSON.stringify(take(input, {
    ProvisionedConcurrentExecutions: []
  }));
  b.m("PUT").h(headers).q(query).b(body);
  return b.build();
};
export const se_PutRuntimeManagementConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2021-07-20/functions/{FunctionName}/runtime-management-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  body = JSON.stringify(take(input, {
    RuntimeVersionArn: [],
    UpdateRuntimeOn: []
  }));
  b.m("PUT").h(headers).q(query).b(body);
  return b.build();
};
export const se_RemoveLayerVersionPermissionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy/{StatementId}");
  b.p("LayerName", () => input.LayerName, "{LayerName}", false);
  b.p("VersionNumber", () => input.VersionNumber.toString(), "{VersionNumber}", false);
  b.p("StatementId", () => input.StatementId, "{StatementId}", false);
  const query = map({
    [_RI]: [, input[_RI]]
  });
  let body;
  b.m("DELETE").h(headers).q(query).b(body);
  return b.build();
};
export const se_RemovePermissionCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2015-03-31/functions/{FunctionName}/policy/{StatementId}");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  b.p("StatementId", () => input.StatementId, "{StatementId}", false);
  const query = map({
    [_Q]: [, input[_Q]],
    [_RI]: [, input[_RI]]
  });
  let body;
  b.m("DELETE").h(headers).q(query).b(body);
  return b.build();
};
export const se_TagResourceCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2017-03-31/tags/{Resource}");
  b.p("Resource", () => input.Resource, "{Resource}", false);
  let body;
  body = JSON.stringify(take(input, {
    Tags: _ => _json(_)
  }));
  b.m("POST").h(headers).b(body);
  return b.build();
};
export const se_UntagResourceCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {};
  b.bp("/2017-03-31/tags/{Resource}");
  b.p("Resource", () => input.Resource, "{Resource}", false);
  const query = map({
    [_tK]: [__expectNonNull(input.TagKeys, `TagKeys`) != null, () => input[_TK] || []]
  });
  let body;
  b.m("DELETE").h(headers).q(query).b(body);
  return b.build();
};
export const se_UpdateAliasCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2015-03-31/functions/{FunctionName}/aliases/{Name}");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  b.p("Name", () => input.Name, "{Name}", false);
  let body;
  body = JSON.stringify(take(input, {
    Description: [],
    FunctionVersion: [],
    RevisionId: [],
    RoutingConfig: _ => se_AliasRoutingConfiguration(_, context)
  }));
  b.m("PUT").h(headers).b(body);
  return b.build();
};
export const se_UpdateCodeSigningConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2020-04-22/code-signing-configs/{CodeSigningConfigArn}");
  b.p("CodeSigningConfigArn", () => input.CodeSigningConfigArn, "{CodeSigningConfigArn}", false);
  let body;
  body = JSON.stringify(take(input, {
    AllowedPublishers: _ => _json(_),
    CodeSigningPolicies: _ => _json(_),
    Description: []
  }));
  b.m("PUT").h(headers).b(body);
  return b.build();
};
export const se_UpdateEventSourceMappingCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2015-03-31/event-source-mappings/{UUID}");
  b.p("UUID", () => input.UUID, "{UUID}", false);
  let body;
  body = JSON.stringify(take(input, {
    BatchSize: [],
    BisectBatchOnFunctionError: [],
    DestinationConfig: _ => _json(_),
    DocumentDBEventSourceConfig: _ => _json(_),
    Enabled: [],
    FilterCriteria: _ => _json(_),
    FunctionName: [],
    FunctionResponseTypes: _ => _json(_),
    KMSKeyArn: [],
    MaximumBatchingWindowInSeconds: [],
    MaximumRecordAgeInSeconds: [],
    MaximumRetryAttempts: [],
    MetricsConfig: _ => _json(_),
    ParallelizationFactor: [],
    ProvisionedPollerConfig: _ => _json(_),
    ScalingConfig: _ => _json(_),
    SourceAccessConfigurations: _ => _json(_),
    TumblingWindowInSeconds: []
  }));
  b.m("PUT").h(headers).b(body);
  return b.build();
};
export const se_UpdateFunctionCodeCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2015-03-31/functions/{FunctionName}/code");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  body = JSON.stringify(take(input, {
    Architectures: _ => _json(_),
    DryRun: [],
    ImageUri: [],
    Publish: [],
    RevisionId: [],
    S3Bucket: [],
    S3Key: [],
    S3ObjectVersion: [],
    SourceKMSKeyArn: [],
    ZipFile: _ => context.base64Encoder(_)
  }));
  b.m("PUT").h(headers).b(body);
  return b.build();
};
export const se_UpdateFunctionConfigurationCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2015-03-31/functions/{FunctionName}/configuration");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  let body;
  body = JSON.stringify(take(input, {
    DeadLetterConfig: _ => _json(_),
    Description: [],
    Environment: _ => _json(_),
    EphemeralStorage: _ => _json(_),
    FileSystemConfigs: _ => _json(_),
    Handler: [],
    ImageConfig: _ => _json(_),
    KMSKeyArn: [],
    Layers: _ => _json(_),
    LoggingConfig: _ => _json(_),
    MemorySize: [],
    RevisionId: [],
    Role: [],
    Runtime: [],
    SnapStart: _ => _json(_),
    Timeout: [],
    TracingConfig: _ => _json(_),
    VpcConfig: _ => _json(_)
  }));
  b.m("PUT").h(headers).b(body);
  return b.build();
};
export const se_UpdateFunctionEventInvokeConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2019-09-25/functions/{FunctionName}/event-invoke-config");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  body = JSON.stringify(take(input, {
    DestinationConfig: _ => _json(_),
    MaximumEventAgeInSeconds: [],
    MaximumRetryAttempts: []
  }));
  b.m("POST").h(headers).q(query).b(body);
  return b.build();
};
export const se_UpdateFunctionUrlConfigCommand = async (input, context) => {
  const b = rb(input, context);
  const headers = {
    "content-type": "application/json"
  };
  b.bp("/2021-10-31/functions/{FunctionName}/url");
  b.p("FunctionName", () => input.FunctionName, "{FunctionName}", false);
  const query = map({
    [_Q]: [, input[_Q]]
  });
  let body;
  body = JSON.stringify(take(input, {
    AuthType: [],
    Cors: _ => _json(_),
    InvokeMode: []
  }));
  b.m("PUT").h(headers).q(query).b(body);
  return b.build();
};
export const de_AddLayerVersionPermissionCommand = async (output, context) => {
  if (output.statusCode !== 201 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    RevisionId: __expectString,
    Statement: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_AddPermissionCommand = async (output, context) => {
  if (output.statusCode !== 201 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Statement: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_CreateAliasCommand = async (output, context) => {
  if (output.statusCode !== 201 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AliasArn: __expectString,
    Description: __expectString,
    FunctionVersion: __expectString,
    Name: __expectString,
    RevisionId: __expectString,
    RoutingConfig: _ => de_AliasRoutingConfiguration(_, context)
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_CreateCodeSigningConfigCommand = async (output, context) => {
  if (output.statusCode !== 201 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    CodeSigningConfig: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_CreateEventSourceMappingCommand = async (output, context) => {
  if (output.statusCode !== 202 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AmazonManagedKafkaEventSourceConfig: _json,
    BatchSize: __expectInt32,
    BisectBatchOnFunctionError: __expectBoolean,
    DestinationConfig: _json,
    DocumentDBEventSourceConfig: _json,
    EventSourceArn: __expectString,
    EventSourceMappingArn: __expectString,
    FilterCriteria: _json,
    FilterCriteriaError: _json,
    FunctionArn: __expectString,
    FunctionResponseTypes: _json,
    KMSKeyArn: __expectString,
    LastModified: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    LastProcessingResult: __expectString,
    MaximumBatchingWindowInSeconds: __expectInt32,
    MaximumRecordAgeInSeconds: __expectInt32,
    MaximumRetryAttempts: __expectInt32,
    MetricsConfig: _json,
    ParallelizationFactor: __expectInt32,
    ProvisionedPollerConfig: _json,
    Queues: _json,
    ScalingConfig: _json,
    SelfManagedEventSource: _json,
    SelfManagedKafkaEventSourceConfig: _json,
    SourceAccessConfigurations: _json,
    StartingPosition: __expectString,
    StartingPositionTimestamp: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    State: __expectString,
    StateTransitionReason: __expectString,
    Topics: _json,
    TumblingWindowInSeconds: __expectInt32,
    UUID: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_CreateFunctionCommand = async (output, context) => {
  if (output.statusCode !== 201 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Architectures: _json,
    CodeSha256: __expectString,
    CodeSize: __expectLong,
    DeadLetterConfig: _json,
    Description: __expectString,
    Environment: _json,
    EphemeralStorage: _json,
    FileSystemConfigs: _json,
    FunctionArn: __expectString,
    FunctionName: __expectString,
    Handler: __expectString,
    ImageConfigResponse: _json,
    KMSKeyArn: __expectString,
    LastModified: __expectString,
    LastUpdateStatus: __expectString,
    LastUpdateStatusReason: __expectString,
    LastUpdateStatusReasonCode: __expectString,
    Layers: _json,
    LoggingConfig: _json,
    MasterArn: __expectString,
    MemorySize: __expectInt32,
    PackageType: __expectString,
    RevisionId: __expectString,
    Role: __expectString,
    Runtime: __expectString,
    RuntimeVersionConfig: _json,
    SigningJobArn: __expectString,
    SigningProfileVersionArn: __expectString,
    SnapStart: _json,
    State: __expectString,
    StateReason: __expectString,
    StateReasonCode: __expectString,
    Timeout: __expectInt32,
    TracingConfig: _json,
    Version: __expectString,
    VpcConfig: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_CreateFunctionUrlConfigCommand = async (output, context) => {
  if (output.statusCode !== 201 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AuthType: __expectString,
    Cors: _json,
    CreationTime: __expectString,
    FunctionArn: __expectString,
    FunctionUrl: __expectString,
    InvokeMode: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_DeleteAliasCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_DeleteCodeSigningConfigCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_DeleteEventSourceMappingCommand = async (output, context) => {
  if (output.statusCode !== 202 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AmazonManagedKafkaEventSourceConfig: _json,
    BatchSize: __expectInt32,
    BisectBatchOnFunctionError: __expectBoolean,
    DestinationConfig: _json,
    DocumentDBEventSourceConfig: _json,
    EventSourceArn: __expectString,
    EventSourceMappingArn: __expectString,
    FilterCriteria: _json,
    FilterCriteriaError: _json,
    FunctionArn: __expectString,
    FunctionResponseTypes: _json,
    KMSKeyArn: __expectString,
    LastModified: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    LastProcessingResult: __expectString,
    MaximumBatchingWindowInSeconds: __expectInt32,
    MaximumRecordAgeInSeconds: __expectInt32,
    MaximumRetryAttempts: __expectInt32,
    MetricsConfig: _json,
    ParallelizationFactor: __expectInt32,
    ProvisionedPollerConfig: _json,
    Queues: _json,
    ScalingConfig: _json,
    SelfManagedEventSource: _json,
    SelfManagedKafkaEventSourceConfig: _json,
    SourceAccessConfigurations: _json,
    StartingPosition: __expectString,
    StartingPositionTimestamp: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    State: __expectString,
    StateTransitionReason: __expectString,
    Topics: _json,
    TumblingWindowInSeconds: __expectInt32,
    UUID: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_DeleteFunctionCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_DeleteFunctionCodeSigningConfigCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_DeleteFunctionConcurrencyCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_DeleteFunctionEventInvokeConfigCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_DeleteFunctionUrlConfigCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_DeleteLayerVersionCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_DeleteProvisionedConcurrencyConfigCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_GetAccountSettingsCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AccountLimit: _json,
    AccountUsage: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetAliasCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AliasArn: __expectString,
    Description: __expectString,
    FunctionVersion: __expectString,
    Name: __expectString,
    RevisionId: __expectString,
    RoutingConfig: _ => de_AliasRoutingConfiguration(_, context)
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetCodeSigningConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    CodeSigningConfig: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetEventSourceMappingCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AmazonManagedKafkaEventSourceConfig: _json,
    BatchSize: __expectInt32,
    BisectBatchOnFunctionError: __expectBoolean,
    DestinationConfig: _json,
    DocumentDBEventSourceConfig: _json,
    EventSourceArn: __expectString,
    EventSourceMappingArn: __expectString,
    FilterCriteria: _json,
    FilterCriteriaError: _json,
    FunctionArn: __expectString,
    FunctionResponseTypes: _json,
    KMSKeyArn: __expectString,
    LastModified: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    LastProcessingResult: __expectString,
    MaximumBatchingWindowInSeconds: __expectInt32,
    MaximumRecordAgeInSeconds: __expectInt32,
    MaximumRetryAttempts: __expectInt32,
    MetricsConfig: _json,
    ParallelizationFactor: __expectInt32,
    ProvisionedPollerConfig: _json,
    Queues: _json,
    ScalingConfig: _json,
    SelfManagedEventSource: _json,
    SelfManagedKafkaEventSourceConfig: _json,
    SourceAccessConfigurations: _json,
    StartingPosition: __expectString,
    StartingPositionTimestamp: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    State: __expectString,
    StateTransitionReason: __expectString,
    Topics: _json,
    TumblingWindowInSeconds: __expectInt32,
    UUID: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetFunctionCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Code: _json,
    Concurrency: _json,
    Configuration: _json,
    Tags: _json,
    TagsError: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetFunctionCodeSigningConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    CodeSigningConfigArn: __expectString,
    FunctionName: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetFunctionConcurrencyCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    ReservedConcurrentExecutions: __expectInt32
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetFunctionConfigurationCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Architectures: _json,
    CodeSha256: __expectString,
    CodeSize: __expectLong,
    DeadLetterConfig: _json,
    Description: __expectString,
    Environment: _json,
    EphemeralStorage: _json,
    FileSystemConfigs: _json,
    FunctionArn: __expectString,
    FunctionName: __expectString,
    Handler: __expectString,
    ImageConfigResponse: _json,
    KMSKeyArn: __expectString,
    LastModified: __expectString,
    LastUpdateStatus: __expectString,
    LastUpdateStatusReason: __expectString,
    LastUpdateStatusReasonCode: __expectString,
    Layers: _json,
    LoggingConfig: _json,
    MasterArn: __expectString,
    MemorySize: __expectInt32,
    PackageType: __expectString,
    RevisionId: __expectString,
    Role: __expectString,
    Runtime: __expectString,
    RuntimeVersionConfig: _json,
    SigningJobArn: __expectString,
    SigningProfileVersionArn: __expectString,
    SnapStart: _json,
    State: __expectString,
    StateReason: __expectString,
    StateReasonCode: __expectString,
    Timeout: __expectInt32,
    TracingConfig: _json,
    Version: __expectString,
    VpcConfig: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetFunctionEventInvokeConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    DestinationConfig: _json,
    FunctionArn: __expectString,
    LastModified: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    MaximumEventAgeInSeconds: __expectInt32,
    MaximumRetryAttempts: __expectInt32
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetFunctionRecursionConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    RecursiveLoop: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetFunctionUrlConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AuthType: __expectString,
    Cors: _json,
    CreationTime: __expectString,
    FunctionArn: __expectString,
    FunctionUrl: __expectString,
    InvokeMode: __expectString,
    LastModifiedTime: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetLayerVersionCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    CompatibleArchitectures: _json,
    CompatibleRuntimes: _json,
    Content: _json,
    CreatedDate: __expectString,
    Description: __expectString,
    LayerArn: __expectString,
    LayerVersionArn: __expectString,
    LicenseInfo: __expectString,
    Version: __expectLong
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetLayerVersionByArnCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    CompatibleArchitectures: _json,
    CompatibleRuntimes: _json,
    Content: _json,
    CreatedDate: __expectString,
    Description: __expectString,
    LayerArn: __expectString,
    LayerVersionArn: __expectString,
    LicenseInfo: __expectString,
    Version: __expectLong
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetLayerVersionPolicyCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Policy: __expectString,
    RevisionId: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetPolicyCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Policy: __expectString,
    RevisionId: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetProvisionedConcurrencyConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AllocatedProvisionedConcurrentExecutions: __expectInt32,
    AvailableProvisionedConcurrentExecutions: __expectInt32,
    LastModified: __expectString,
    RequestedProvisionedConcurrentExecutions: __expectInt32,
    Status: __expectString,
    StatusReason: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_GetRuntimeManagementConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    FunctionArn: __expectString,
    RuntimeVersionArn: __expectString,
    UpdateRuntimeOn: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_InvokeCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output),
    [_FE]: [, output.headers[_xafe]],
    [_LR]: [, output.headers[_xalr]],
    [_EV]: [, output.headers[_xaev]]
  });
  const data = await collectBody(output.body, context);
  contents.Payload = data;
  map(contents, {
    StatusCode: [, output.statusCode]
  });
  return contents;
};
export const de_InvokeAsyncCommand = async (output, context) => {
  if (output.statusCode !== 202 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  map(contents, {
    Status: [, output.statusCode]
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_InvokeWithResponseStreamCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output),
    [_EV]: [, output.headers[_xaev]],
    [_RSCT]: [, output.headers[_ct]]
  });
  const data = output.body;
  contents.EventStream = de_InvokeWithResponseStreamResponseEvent(data, context);
  map(contents, {
    StatusCode: [, output.statusCode]
  });
  return contents;
};
export const de_ListAliasesCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Aliases: _ => de_AliasList(_, context),
    NextMarker: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListCodeSigningConfigsCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    CodeSigningConfigs: _json,
    NextMarker: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListEventSourceMappingsCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    EventSourceMappings: _ => de_EventSourceMappingsList(_, context),
    NextMarker: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListFunctionEventInvokeConfigsCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    FunctionEventInvokeConfigs: _ => de_FunctionEventInvokeConfigList(_, context),
    NextMarker: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListFunctionsCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Functions: _json,
    NextMarker: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListFunctionsByCodeSigningConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    FunctionArns: _json,
    NextMarker: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListFunctionUrlConfigsCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    FunctionUrlConfigs: _json,
    NextMarker: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListLayersCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Layers: _json,
    NextMarker: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListLayerVersionsCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    LayerVersions: _json,
    NextMarker: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListProvisionedConcurrencyConfigsCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    NextMarker: __expectString,
    ProvisionedConcurrencyConfigs: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListTagsCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Tags: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_ListVersionsByFunctionCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    NextMarker: __expectString,
    Versions: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_PublishLayerVersionCommand = async (output, context) => {
  if (output.statusCode !== 201 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    CompatibleArchitectures: _json,
    CompatibleRuntimes: _json,
    Content: _json,
    CreatedDate: __expectString,
    Description: __expectString,
    LayerArn: __expectString,
    LayerVersionArn: __expectString,
    LicenseInfo: __expectString,
    Version: __expectLong
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_PublishVersionCommand = async (output, context) => {
  if (output.statusCode !== 201 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Architectures: _json,
    CodeSha256: __expectString,
    CodeSize: __expectLong,
    DeadLetterConfig: _json,
    Description: __expectString,
    Environment: _json,
    EphemeralStorage: _json,
    FileSystemConfigs: _json,
    FunctionArn: __expectString,
    FunctionName: __expectString,
    Handler: __expectString,
    ImageConfigResponse: _json,
    KMSKeyArn: __expectString,
    LastModified: __expectString,
    LastUpdateStatus: __expectString,
    LastUpdateStatusReason: __expectString,
    LastUpdateStatusReasonCode: __expectString,
    Layers: _json,
    LoggingConfig: _json,
    MasterArn: __expectString,
    MemorySize: __expectInt32,
    PackageType: __expectString,
    RevisionId: __expectString,
    Role: __expectString,
    Runtime: __expectString,
    RuntimeVersionConfig: _json,
    SigningJobArn: __expectString,
    SigningProfileVersionArn: __expectString,
    SnapStart: _json,
    State: __expectString,
    StateReason: __expectString,
    StateReasonCode: __expectString,
    Timeout: __expectInt32,
    TracingConfig: _json,
    Version: __expectString,
    VpcConfig: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_PutFunctionCodeSigningConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    CodeSigningConfigArn: __expectString,
    FunctionName: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_PutFunctionConcurrencyCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    ReservedConcurrentExecutions: __expectInt32
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_PutFunctionEventInvokeConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    DestinationConfig: _json,
    FunctionArn: __expectString,
    LastModified: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    MaximumEventAgeInSeconds: __expectInt32,
    MaximumRetryAttempts: __expectInt32
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_PutFunctionRecursionConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    RecursiveLoop: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_PutProvisionedConcurrencyConfigCommand = async (output, context) => {
  if (output.statusCode !== 202 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AllocatedProvisionedConcurrentExecutions: __expectInt32,
    AvailableProvisionedConcurrentExecutions: __expectInt32,
    LastModified: __expectString,
    RequestedProvisionedConcurrentExecutions: __expectInt32,
    Status: __expectString,
    StatusReason: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_PutRuntimeManagementConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    FunctionArn: __expectString,
    RuntimeVersionArn: __expectString,
    UpdateRuntimeOn: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_RemoveLayerVersionPermissionCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_RemovePermissionCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_TagResourceCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_UntagResourceCommand = async (output, context) => {
  if (output.statusCode !== 204 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  await collectBody(output.body, context);
  return contents;
};
export const de_UpdateAliasCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AliasArn: __expectString,
    Description: __expectString,
    FunctionVersion: __expectString,
    Name: __expectString,
    RevisionId: __expectString,
    RoutingConfig: _ => de_AliasRoutingConfiguration(_, context)
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_UpdateCodeSigningConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    CodeSigningConfig: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_UpdateEventSourceMappingCommand = async (output, context) => {
  if (output.statusCode !== 202 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AmazonManagedKafkaEventSourceConfig: _json,
    BatchSize: __expectInt32,
    BisectBatchOnFunctionError: __expectBoolean,
    DestinationConfig: _json,
    DocumentDBEventSourceConfig: _json,
    EventSourceArn: __expectString,
    EventSourceMappingArn: __expectString,
    FilterCriteria: _json,
    FilterCriteriaError: _json,
    FunctionArn: __expectString,
    FunctionResponseTypes: _json,
    KMSKeyArn: __expectString,
    LastModified: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    LastProcessingResult: __expectString,
    MaximumBatchingWindowInSeconds: __expectInt32,
    MaximumRecordAgeInSeconds: __expectInt32,
    MaximumRetryAttempts: __expectInt32,
    MetricsConfig: _json,
    ParallelizationFactor: __expectInt32,
    ProvisionedPollerConfig: _json,
    Queues: _json,
    ScalingConfig: _json,
    SelfManagedEventSource: _json,
    SelfManagedKafkaEventSourceConfig: _json,
    SourceAccessConfigurations: _json,
    StartingPosition: __expectString,
    StartingPositionTimestamp: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    State: __expectString,
    StateTransitionReason: __expectString,
    Topics: _json,
    TumblingWindowInSeconds: __expectInt32,
    UUID: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_UpdateFunctionCodeCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Architectures: _json,
    CodeSha256: __expectString,
    CodeSize: __expectLong,
    DeadLetterConfig: _json,
    Description: __expectString,
    Environment: _json,
    EphemeralStorage: _json,
    FileSystemConfigs: _json,
    FunctionArn: __expectString,
    FunctionName: __expectString,
    Handler: __expectString,
    ImageConfigResponse: _json,
    KMSKeyArn: __expectString,
    LastModified: __expectString,
    LastUpdateStatus: __expectString,
    LastUpdateStatusReason: __expectString,
    LastUpdateStatusReasonCode: __expectString,
    Layers: _json,
    LoggingConfig: _json,
    MasterArn: __expectString,
    MemorySize: __expectInt32,
    PackageType: __expectString,
    RevisionId: __expectString,
    Role: __expectString,
    Runtime: __expectString,
    RuntimeVersionConfig: _json,
    SigningJobArn: __expectString,
    SigningProfileVersionArn: __expectString,
    SnapStart: _json,
    State: __expectString,
    StateReason: __expectString,
    StateReasonCode: __expectString,
    Timeout: __expectInt32,
    TracingConfig: _json,
    Version: __expectString,
    VpcConfig: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_UpdateFunctionConfigurationCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    Architectures: _json,
    CodeSha256: __expectString,
    CodeSize: __expectLong,
    DeadLetterConfig: _json,
    Description: __expectString,
    Environment: _json,
    EphemeralStorage: _json,
    FileSystemConfigs: _json,
    FunctionArn: __expectString,
    FunctionName: __expectString,
    Handler: __expectString,
    ImageConfigResponse: _json,
    KMSKeyArn: __expectString,
    LastModified: __expectString,
    LastUpdateStatus: __expectString,
    LastUpdateStatusReason: __expectString,
    LastUpdateStatusReasonCode: __expectString,
    Layers: _json,
    LoggingConfig: _json,
    MasterArn: __expectString,
    MemorySize: __expectInt32,
    PackageType: __expectString,
    RevisionId: __expectString,
    Role: __expectString,
    Runtime: __expectString,
    RuntimeVersionConfig: _json,
    SigningJobArn: __expectString,
    SigningProfileVersionArn: __expectString,
    SnapStart: _json,
    State: __expectString,
    StateReason: __expectString,
    StateReasonCode: __expectString,
    Timeout: __expectInt32,
    TracingConfig: _json,
    Version: __expectString,
    VpcConfig: _json
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_UpdateFunctionEventInvokeConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    DestinationConfig: _json,
    FunctionArn: __expectString,
    LastModified: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    MaximumEventAgeInSeconds: __expectInt32,
    MaximumRetryAttempts: __expectInt32
  });
  Object.assign(contents, doc);
  return contents;
};
export const de_UpdateFunctionUrlConfigCommand = async (output, context) => {
  if (output.statusCode !== 200 && output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const contents = map({
    $metadata: deserializeMetadata(output)
  });
  const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
  const doc = take(data, {
    AuthType: __expectString,
    Cors: _json,
    CreationTime: __expectString,
    FunctionArn: __expectString,
    FunctionUrl: __expectString,
    InvokeMode: __expectString,
    LastModifiedTime: __expectString
  });
  Object.assign(contents, doc);
  return contents;
};
const de_CommandError = async (output, context) => {
  const parsedOutput = {
    ...output,
    body: await parseErrorBody(output.body, context)
  };
  const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
  switch (errorCode) {
    case "InvalidParameterValueException":
    case "com.amazonaws.lambda#InvalidParameterValueException":
      throw await de_InvalidParameterValueExceptionRes(parsedOutput, context);
    case "PolicyLengthExceededException":
    case "com.amazonaws.lambda#PolicyLengthExceededException":
      throw await de_PolicyLengthExceededExceptionRes(parsedOutput, context);
    case "PreconditionFailedException":
    case "com.amazonaws.lambda#PreconditionFailedException":
      throw await de_PreconditionFailedExceptionRes(parsedOutput, context);
    case "ResourceConflictException":
    case "com.amazonaws.lambda#ResourceConflictException":
      throw await de_ResourceConflictExceptionRes(parsedOutput, context);
    case "ResourceNotFoundException":
    case "com.amazonaws.lambda#ResourceNotFoundException":
      throw await de_ResourceNotFoundExceptionRes(parsedOutput, context);
    case "ServiceException":
    case "com.amazonaws.lambda#ServiceException":
      throw await de_ServiceExceptionRes(parsedOutput, context);
    case "TooManyRequestsException":
    case "com.amazonaws.lambda#TooManyRequestsException":
      throw await de_TooManyRequestsExceptionRes(parsedOutput, context);
    case "CodeSigningConfigNotFoundException":
    case "com.amazonaws.lambda#CodeSigningConfigNotFoundException":
      throw await de_CodeSigningConfigNotFoundExceptionRes(parsedOutput, context);
    case "CodeStorageExceededException":
    case "com.amazonaws.lambda#CodeStorageExceededException":
      throw await de_CodeStorageExceededExceptionRes(parsedOutput, context);
    case "CodeVerificationFailedException":
    case "com.amazonaws.lambda#CodeVerificationFailedException":
      throw await de_CodeVerificationFailedExceptionRes(parsedOutput, context);
    case "InvalidCodeSignatureException":
    case "com.amazonaws.lambda#InvalidCodeSignatureException":
      throw await de_InvalidCodeSignatureExceptionRes(parsedOutput, context);
    case "ResourceInUseException":
    case "com.amazonaws.lambda#ResourceInUseException":
      throw await de_ResourceInUseExceptionRes(parsedOutput, context);
    case "ProvisionedConcurrencyConfigNotFoundException":
    case "com.amazonaws.lambda#ProvisionedConcurrencyConfigNotFoundException":
      throw await de_ProvisionedConcurrencyConfigNotFoundExceptionRes(parsedOutput, context);
    case "EC2AccessDeniedException":
    case "com.amazonaws.lambda#EC2AccessDeniedException":
      throw await de_EC2AccessDeniedExceptionRes(parsedOutput, context);
    case "EC2ThrottledException":
    case "com.amazonaws.lambda#EC2ThrottledException":
      throw await de_EC2ThrottledExceptionRes(parsedOutput, context);
    case "EC2UnexpectedException":
    case "com.amazonaws.lambda#EC2UnexpectedException":
      throw await de_EC2UnexpectedExceptionRes(parsedOutput, context);
    case "EFSIOException":
    case "com.amazonaws.lambda#EFSIOException":
      throw await de_EFSIOExceptionRes(parsedOutput, context);
    case "EFSMountConnectivityException":
    case "com.amazonaws.lambda#EFSMountConnectivityException":
      throw await de_EFSMountConnectivityExceptionRes(parsedOutput, context);
    case "EFSMountFailureException":
    case "com.amazonaws.lambda#EFSMountFailureException":
      throw await de_EFSMountFailureExceptionRes(parsedOutput, context);
    case "EFSMountTimeoutException":
    case "com.amazonaws.lambda#EFSMountTimeoutException":
      throw await de_EFSMountTimeoutExceptionRes(parsedOutput, context);
    case "ENILimitReachedException":
    case "com.amazonaws.lambda#ENILimitReachedException":
      throw await de_ENILimitReachedExceptionRes(parsedOutput, context);
    case "InvalidRequestContentException":
    case "com.amazonaws.lambda#InvalidRequestContentException":
      throw await de_InvalidRequestContentExceptionRes(parsedOutput, context);
    case "InvalidRuntimeException":
    case "com.amazonaws.lambda#InvalidRuntimeException":
      throw await de_InvalidRuntimeExceptionRes(parsedOutput, context);
    case "InvalidSecurityGroupIDException":
    case "com.amazonaws.lambda#InvalidSecurityGroupIDException":
      throw await de_InvalidSecurityGroupIDExceptionRes(parsedOutput, context);
    case "InvalidSubnetIDException":
    case "com.amazonaws.lambda#InvalidSubnetIDException":
      throw await de_InvalidSubnetIDExceptionRes(parsedOutput, context);
    case "InvalidZipFileException":
    case "com.amazonaws.lambda#InvalidZipFileException":
      throw await de_InvalidZipFileExceptionRes(parsedOutput, context);
    case "KMSAccessDeniedException":
    case "com.amazonaws.lambda#KMSAccessDeniedException":
      throw await de_KMSAccessDeniedExceptionRes(parsedOutput, context);
    case "KMSDisabledException":
    case "com.amazonaws.lambda#KMSDisabledException":
      throw await de_KMSDisabledExceptionRes(parsedOutput, context);
    case "KMSInvalidStateException":
    case "com.amazonaws.lambda#KMSInvalidStateException":
      throw await de_KMSInvalidStateExceptionRes(parsedOutput, context);
    case "KMSNotFoundException":
    case "com.amazonaws.lambda#KMSNotFoundException":
      throw await de_KMSNotFoundExceptionRes(parsedOutput, context);
    case "RecursiveInvocationException":
    case "com.amazonaws.lambda#RecursiveInvocationException":
      throw await de_RecursiveInvocationExceptionRes(parsedOutput, context);
    case "RequestTooLargeException":
    case "com.amazonaws.lambda#RequestTooLargeException":
      throw await de_RequestTooLargeExceptionRes(parsedOutput, context);
    case "ResourceNotReadyException":
    case "com.amazonaws.lambda#ResourceNotReadyException":
      throw await de_ResourceNotReadyExceptionRes(parsedOutput, context);
    case "SnapStartException":
    case "com.amazonaws.lambda#SnapStartException":
      throw await de_SnapStartExceptionRes(parsedOutput, context);
    case "SnapStartNotReadyException":
    case "com.amazonaws.lambda#SnapStartNotReadyException":
      throw await de_SnapStartNotReadyExceptionRes(parsedOutput, context);
    case "SnapStartTimeoutException":
    case "com.amazonaws.lambda#SnapStartTimeoutException":
      throw await de_SnapStartTimeoutExceptionRes(parsedOutput, context);
    case "SubnetIPAddressLimitReachedException":
    case "com.amazonaws.lambda#SubnetIPAddressLimitReachedException":
      throw await de_SubnetIPAddressLimitReachedExceptionRes(parsedOutput, context);
    case "UnsupportedMediaTypeException":
    case "com.amazonaws.lambda#UnsupportedMediaTypeException":
      throw await de_UnsupportedMediaTypeExceptionRes(parsedOutput, context);
    default:
      const parsedBody = parsedOutput.body;
      return throwDefaultError({
        output,
        parsedBody,
        errorCode
      });
  }
};
const throwDefaultError = withBaseException(__BaseException);
const de_CodeSigningConfigNotFoundExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new CodeSigningConfigNotFoundException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_CodeStorageExceededExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new CodeStorageExceededException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_CodeVerificationFailedExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new CodeVerificationFailedException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_EC2AccessDeniedExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new EC2AccessDeniedException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_EC2ThrottledExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new EC2ThrottledException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_EC2UnexpectedExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    EC2ErrorCode: __expectString,
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new EC2UnexpectedException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_EFSIOExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new EFSIOException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_EFSMountConnectivityExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new EFSMountConnectivityException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_EFSMountFailureExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new EFSMountFailureException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_EFSMountTimeoutExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new EFSMountTimeoutException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_ENILimitReachedExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new ENILimitReachedException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_InvalidCodeSignatureExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new InvalidCodeSignatureException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_InvalidParameterValueExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new InvalidParameterValueException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_InvalidRequestContentExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new InvalidRequestContentException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_InvalidRuntimeExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new InvalidRuntimeException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_InvalidSecurityGroupIDExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new InvalidSecurityGroupIDException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_InvalidSubnetIDExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new InvalidSubnetIDException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_InvalidZipFileExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new InvalidZipFileException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_KMSAccessDeniedExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new KMSAccessDeniedException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_KMSDisabledExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new KMSDisabledException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_KMSInvalidStateExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new KMSInvalidStateException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_KMSNotFoundExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new KMSNotFoundException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_PolicyLengthExceededExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new PolicyLengthExceededException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_PreconditionFailedExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new PreconditionFailedException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_ProvisionedConcurrencyConfigNotFoundExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new ProvisionedConcurrencyConfigNotFoundException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_RecursiveInvocationExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new RecursiveInvocationException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_RequestTooLargeExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new RequestTooLargeException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_ResourceConflictExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new ResourceConflictException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_ResourceInUseExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new ResourceInUseException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_ResourceNotFoundExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new ResourceNotFoundException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_ResourceNotReadyExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new ResourceNotReadyException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_ServiceExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new ServiceException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_SnapStartExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new SnapStartException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_SnapStartNotReadyExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new SnapStartNotReadyException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_SnapStartTimeoutExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new SnapStartTimeoutException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_SubnetIPAddressLimitReachedExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Message: __expectString,
    Type: __expectString
  });
  Object.assign(contents, doc);
  const exception = new SubnetIPAddressLimitReachedException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_TooManyRequestsExceptionRes = async (parsedOutput, context) => {
  const contents = map({
    [_rAS]: [, parsedOutput.headers[_ra]]
  });
  const data = parsedOutput.body;
  const doc = take(data, {
    Reason: __expectString,
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new TooManyRequestsException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_UnsupportedMediaTypeExceptionRes = async (parsedOutput, context) => {
  const contents = map({});
  const data = parsedOutput.body;
  const doc = take(data, {
    Type: __expectString,
    message: __expectString
  });
  Object.assign(contents, doc);
  const exception = new UnsupportedMediaTypeException({
    $metadata: deserializeMetadata(parsedOutput),
    ...contents
  });
  return __decorateServiceException(exception, parsedOutput.body);
};
const de_InvokeWithResponseStreamResponseEvent = (output, context) => {
  return context.eventStreamMarshaller.deserialize(output, async event => {
    if (event["PayloadChunk"] != null) {
      return {
        PayloadChunk: await de_InvokeResponseStreamUpdate_event(event["PayloadChunk"], context)
      };
    }
    if (event["InvokeComplete"] != null) {
      return {
        InvokeComplete: await de_InvokeWithResponseStreamCompleteEvent_event(event["InvokeComplete"], context)
      };
    }
    return {
      $unknown: output
    };
  });
};
const de_InvokeResponseStreamUpdate_event = async (output, context) => {
  const contents = {};
  contents.Payload = output.body;
  return contents;
};
const de_InvokeWithResponseStreamCompleteEvent_event = async (output, context) => {
  const contents = {};
  const data = await parseBody(output.body, context);
  Object.assign(contents, _json(data));
  return contents;
};
const se_AdditionalVersionWeights = (input, context) => {
  return Object.entries(input).reduce((acc, [key, value]) => {
    if (value === null) {
      return acc;
    }
    acc[key] = __serializeFloat(value);
    return acc;
  }, {});
};
const se_AliasRoutingConfiguration = (input, context) => {
  return take(input, {
    AdditionalVersionWeights: _ => se_AdditionalVersionWeights(_, context)
  });
};
const se_FunctionCode = (input, context) => {
  return take(input, {
    ImageUri: [],
    S3Bucket: [],
    S3Key: [],
    S3ObjectVersion: [],
    SourceKMSKeyArn: [],
    ZipFile: context.base64Encoder
  });
};
const se_LayerVersionContentInput = (input, context) => {
  return take(input, {
    S3Bucket: [],
    S3Key: [],
    S3ObjectVersion: [],
    ZipFile: context.base64Encoder
  });
};
const de_AdditionalVersionWeights = (output, context) => {
  return Object.entries(output).reduce((acc, [key, value]) => {
    if (value === null) {
      return acc;
    }
    acc[key] = __limitedParseDouble(value);
    return acc;
  }, {});
};
const de_AliasConfiguration = (output, context) => {
  return take(output, {
    AliasArn: __expectString,
    Description: __expectString,
    FunctionVersion: __expectString,
    Name: __expectString,
    RevisionId: __expectString,
    RoutingConfig: _ => de_AliasRoutingConfiguration(_, context)
  });
};
const de_AliasList = (output, context) => {
  const retVal = (output || []).filter(e => e != null).map(entry => {
    return de_AliasConfiguration(entry, context);
  });
  return retVal;
};
const de_AliasRoutingConfiguration = (output, context) => {
  return take(output, {
    AdditionalVersionWeights: _ => de_AdditionalVersionWeights(_, context)
  });
};
const de_EventSourceMappingConfiguration = (output, context) => {
  return take(output, {
    AmazonManagedKafkaEventSourceConfig: _json,
    BatchSize: __expectInt32,
    BisectBatchOnFunctionError: __expectBoolean,
    DestinationConfig: _json,
    DocumentDBEventSourceConfig: _json,
    EventSourceArn: __expectString,
    EventSourceMappingArn: __expectString,
    FilterCriteria: _json,
    FilterCriteriaError: _json,
    FunctionArn: __expectString,
    FunctionResponseTypes: _json,
    KMSKeyArn: __expectString,
    LastModified: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    LastProcessingResult: __expectString,
    MaximumBatchingWindowInSeconds: __expectInt32,
    MaximumRecordAgeInSeconds: __expectInt32,
    MaximumRetryAttempts: __expectInt32,
    MetricsConfig: _json,
    ParallelizationFactor: __expectInt32,
    ProvisionedPollerConfig: _json,
    Queues: _json,
    ScalingConfig: _json,
    SelfManagedEventSource: _json,
    SelfManagedKafkaEventSourceConfig: _json,
    SourceAccessConfigurations: _json,
    StartingPosition: __expectString,
    StartingPositionTimestamp: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    State: __expectString,
    StateTransitionReason: __expectString,
    Topics: _json,
    TumblingWindowInSeconds: __expectInt32,
    UUID: __expectString
  });
};
const de_EventSourceMappingsList = (output, context) => {
  const retVal = (output || []).filter(e => e != null).map(entry => {
    return de_EventSourceMappingConfiguration(entry, context);
  });
  return retVal;
};
const de_FunctionEventInvokeConfig = (output, context) => {
  return take(output, {
    DestinationConfig: _json,
    FunctionArn: __expectString,
    LastModified: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    MaximumEventAgeInSeconds: __expectInt32,
    MaximumRetryAttempts: __expectInt32
  });
};
const de_FunctionEventInvokeConfigList = (output, context) => {
  const retVal = (output || []).filter(e => e != null).map(entry => {
    return de_FunctionEventInvokeConfig(entry, context);
  });
  return retVal;
};
const deserializeMetadata = output => ({
  httpStatusCode: output.statusCode,
  requestId: output.headers["x-amzn-requestid"] ?? output.headers["x-amzn-request-id"] ?? output.headers["x-amz-request-id"],
  extendedRequestId: output.headers["x-amz-id-2"],
  cfId: output.headers["x-amz-cf-id"]
});
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then(body => context.utf8Encoder(body));
const _A = "Arn";
const _CA = "CompatibleArchitecture";
const _CC = "ClientContext";
const _CR = "CompatibleRuntime";
const _ESA = "EventSourceArn";
const _EV = "ExecutedVersion";
const _FE = "FunctionError";
const _FN = "FunctionName";
const _FV = "FunctionVersion";
const _IT = "InvocationType";
const _L = "List";
const _LR = "LogResult";
const _LT = "LogType";
const _M = "Marker";
const _MI = "MaxItems";
const _MR = "MasterRegion";
const _Q = "Qualifier";
const _RI = "RevisionId";
const _RSCT = "ResponseStreamContentType";
const _TK = "TagKeys";
const _ct = "content-type";
const _f = "find";
const _rAS = "retryAfterSeconds";
const _ra = "retry-after";
const _tK = "tagKeys";
const _xacc = "x-amz-client-context";
const _xaev = "x-amz-executed-version";
const _xafe = "x-amz-function-error";
const _xait = "x-amz-invocation-type";
const _xalr = "x-amz-log-result";
const _xalt = "x-amz-log-type";