import {ApplicationRef, inject, Injectable, signal} from '@angular/core'
import {SwUpdate} from '@angular/service-worker'
import {concat, filter, interval, Subject, takeUntil, timer} from 'rxjs'
import {first, switchMap} from 'rxjs/operators'
import {DebugService} from './debug.service'
import {AwsService} from './aws.service'
import {UserService} from './user.service'

export const UPDATE_CHECK_INTERVAL = 60 * 1000

@Injectable({providedIn: 'root'})
export class SwUpdateService {
  private debugService = inject(DebugService)
  private awsService = inject(AwsService)
  private userService = inject(UserService)
  private pause = new Subject<boolean>()

  public swUpdateAvailable = signal<boolean>(false)

  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable))
    const everyHour$ = interval(15 * 60 * 1000)
    const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$)

    everyHourOnceAppIsStable$.pipe().subscribe(async () => {
      try {
        const update = await updates.checkForUpdate()
        this.debugService.log(`Version checked: ${update}`)
        this.swUpdateAvailable.set(update)
      } catch {
      }
    })
  }

  public pauseUpdateCheck(): void {
    this.pause.next(true)
  }

  public startUpdateCheck(): void {
    timer(0, UPDATE_CHECK_INTERVAL).pipe(
      takeUntil(this.pause),
      switchMap(() => {
        return this.awsService.getStatusFromLambda()
      }),
      filter(Boolean),
      switchMap(() => this.userService.getSelf())
    ).subscribe()
  }
}